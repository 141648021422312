











import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import HomeNav from "./components/home-nav.vue"
import HomeModule from "./components/home-module.vue"
import homeData from '../../assets/data/home'
import { backTop } from '../../utils/utils'

@Component({
  components: { vFreeApply,VBanner,HomeNav, HomeModule }})
export default class extends Vue {
  private bannerData = homeData.banner
  private moduleData = homeData.moduleData
  mounted(){
    backTop()
  }
}
