













import { Component, Prop, Vue } from "vue-property-decorator";
import vVideo from './v-video.vue'

@Component({
  components: { vVideo }
})
export default class extends Vue {

  private vVideoVisible = false

  @Prop() titleImg !: string
  @Prop() videoSrc !: string
  @Prop() videoPoster !: string
  @Prop() videoTitle !: string
  @Prop() videoDec !: string
  
}
