export default{
  banner: {
    titleImg: require('../images/home/banner_title.png'),
    video:{
      src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/72849dd-179407a33f9-0005-502e-0e5-a9c95.mp4',
      poster: 'http://www.xmheigu.com/images/ban-p5.jpg',
      title: '',
      dec: '实体门店如何在当今经营局势下找到突破口？转变经营思维，创新商业模式是出路。黑谷在商业模式+管理软件+落地服务三大版块助力门店老板解决经营问题。如何让门店营业额快速翻倍，黑谷可以帮你。'
    }
  },
  moduleData: [
  
    {
      title: '员工合伙',
      dec: '用机制管理替代传统管理，1招点燃员工积极性',
      content: [
        '稳定优秀的员工',
        '点燃每一个员工的服务热情',
        '让每个员工都和老板一样创造业绩'
      ],
      video:{
        src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/2e828f64-17920cb29da-0005-502e-0e5-a9c95.mp4',
        poster: require('../images/public/poster/staff.jpg'),
        title: '',
        dec: '如何让员工像老板一样积极干活？如何通过机制实现员工优胜劣汰？员工合伙机制一招可以解决。一个好的管理机制不仅可以点燃员工积极性，还可以帮助门店提升服务品质，锁住稳定客源，实现持续盈利增收。'
      }
    },
    {
      title: '抖音运营',
      dec: '快速学习互联网营销工具，最低成本引爆客源',
      content: [
        '客户定位更精准',
        '引流活动有效到达',
        '沉睡客户重复唤醒'
      ],
      video:{
        src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/3e62afd8-17920bfc461-0005-502e-0e5-a9c95.mp4',
        poster: require('../images/public/poster/trill.jpg'),
        title: '',
        dec: '门店做抖音，绝不仅仅是做粉丝量，更重要的是吸引准确的客户来店里买单。如何最低成本在抖音获客，黑谷专业团队可以帮你。原本发不出去的传单，在抖音统统可以实现。抓住抖音红利期，把自己的门店做成抖音网红店是实体行业新的出路。'
      }
    },
    {
      title: '共享商圈',
      dec: '把1个人的生意变成1群人的生意',
      content: [
        '区别于传统异业联盟',
        '把门店客源快速翻倍增收',
        '为门店引流更多客源'
      ],
      video:{
        src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/171f2afc-17920c5f65f-0005-502e-0e5-a9c95.mp4',
        poster: require('../images/public/poster/business.jpg'),
        title: '',
        dec: '如何让你的100个客户秒变1000个？如何让一群人帮你一起拓客？单靠传统的异业联盟模式已经行不通了。共享商圈可以帮你解决持续的拓客问题，要集结整个商圈的资源，模式加系统才是完整解决方案。'
      }
    }
  ]
}